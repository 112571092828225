<template>
  <div class="form_start_main">
    <vs-input v-validate="'required|email|min:3'" data-vv-validate-on="blur" name="email" icon-no-border icon="icon icon-at-sign" icon-pack="feather" v-model="email" :maxlength=320 class="w-full"/>
   <b> <span style="background:red; color:white"class="text-sm">{{ errors.first('email') }}</span></b>

    <vs-input data-vv-validate-on="blur" :maxlength=128 v-validate="'required|min:6|max:15'" type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather" v-model="password" class="w-full mt-6" />
    <b><span style="background:red; color:white" class=" text-sm">{{ errors.first('password') }}</span></b>
    <input type="hidden" name="employee_type" class="form-control" :value="3">
    
    <div class="flex flex-wrap my-5 mb-3 login_btn">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
    <!-- <div class="flex flex-wrap justify-between my-5 forget_password">
        <router-link to="/pages/forgot-password">Forgot Password?</router-link>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      //email: 'support@isabel.sg',
      //password: 'Isabel@123',
      checkbox_remember_me: false,
      email:'',
      password:''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.email  && this.password !== '' && this.password
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    loginJWT () {

      // if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => { 
          var currentDate1 = new Date();
          var currentMonth = currentDate1.getMonth() + 1
          if(currentMonth < 10) {
              currentMonth = '0' + currentMonth;
          } else {
              currentMonth = '' + currentMonth;
          }
        const todate = `${currentDate1.getFullYear()}-${currentMonth }-${currentDate1.getDate() + 1}`;
           localStorage.setItem('login_date',todate)
          this.$vs.loading.close() })
        .catch(error => {
          console.log(error.message)
          if(error.message == "Network Error" || error.message =="User is not confirmed."){
            error.message ='User Not Exists...!!!'
          }else if(error.message =="Incorrect username or password."){
            error.message = error.message
          }else if(error.message =="User does not exist."){
            error.message = error.message
          }else{
            error.message = 'Network Issue Please Try To Login After Sometime...!!!'
          }
         
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Alert',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    }
  }
}

</script>

