<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
    <div class="isbel_login_main">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color none_bg">

          <div class="vx-row no-gutter justify-center items-center">

           

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 ">
             <div class="login-tabs-container-main">
              <div class="login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-6"><img src="/main-logo.jpg" alt="login" class="mx-auto"></h4>
                  <h5 class="mb-5">LOGIN TO YOUR ACCOUNT</h5>
                </div>

                <login-jwt></login-jwt>

              </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    </div>
  </div>
</template>


<script>
import LoginJwt from './LoginJWT.vue'

export default {
  components: {
    LoginJwt
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
